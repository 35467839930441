import React from 'react';
import "./Msoftware.css";
import free from "../assets-p/training-icon.png";
import msme from "../assets-p/for-msme-icon.png";
import easy from "../assets-p/easy-ui-icon.png";
import reporting from "../assets-p/data-reports-prod-icon.png";
import sub from "../assets-p/scrap-mgt-icon.png";

const Msoftware = () => {
  const images = [
    { src: free, text: 'Free employee training' },
    { src: msme, text: 'Designed for MSMEs' },
    { src: easy, text: 'Easy Interface' },
    { src: reporting, text: 'Advanced Reporting'},
    //{ src: sub, text: (<>Subcontract <br/> Management</>) },
  ];

  return (
    <div className='Msoftware'>
      <div className="line-container-ms">
        <div className="line-text-ms">
          <h3 className='text-s1-ms'>
            <span className='Sp-On-ms'>One-stop solution</span> for all your inventory management needs
          </h3>
        </div>
      </div>
      <div className="image-grid-ms">
        {images.map((image, index) => (
          <div className="image-item-ms" key={index}>
            <img src={image.src} alt={`Image ${index + 1}`} />
            <p>{image.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Msoftware;

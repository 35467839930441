import React from 'react';
import './Manage.css';

const Manage = () => {
  return (
    <div className='Manage'>
      <div className='Manage-text'>
        <h1 className='m-text1'>Inventory Management</h1>
        <h1 className='m-text2'>For Manufacturers</h1>
        <hr className='m-hr'/>
        <h3 className='m-text3'>Inventory Management is the most pressing problem faced by Indian MSMEs, but not anymore</h3>
      </div>
      <div className='Manage-text2'>
        <p className='p-mg-tx'>Inventory Tracking</p>
        <p className='p-mg-tx'>Order Management</p>
        <p className='p-mg-tx'>GST Billing</p>
        <p className='p-mg-tx'>Warehouse Management</p>
        <p className='p-mg-tx'>Barcode Batch Tracking</p>
      </div>
    </div>
  );
}

export default Manage;

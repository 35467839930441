import React, { useState } from 'react';
import "./PConeStop.css";
import purchase from "../assets-pc/purchase-plan-snap.png";
import purchaserq from "../assets-pc/purchase-req-snap.png";
import inward from "../assets-pc/inward-qir-snap.png";
import trans from "../assets-pc/trans-time-snap.png";
import team from "../assets-pc/team-colab-snap.png";


const optionImages = {
  'Purchase Planning': purchase,
  'Purchase Requisition': purchaserq,
  'Inward and QR': inward,
  'Transaction Timeline': trans,
  'Team Collaboration': team,
};

const optionTexts = {
  'Purchase Planning':<><span className='highlight'>Create MRP reports</span> in a few seconds to accurately measure your purchase requirements to continuously improve lead conversion</>,
  'Purchase Requisition': <><span className='highlight'>Create and track</span> Purchase Requisitions easily and always maintain optimum stock</>,
  'Inward and QR': <><span className='highlight'>Create and track</span> Purchase Requisitions easily and always maintain optimum stock</>,
  'Transaction Timeline': <><span className='highlight'>Track the entire timeline</span> of your transactions (i.e OC, Proforma, ARV, Invoice, Challan etc) at a single place.</>,
  'Team Collaboration': <><span className='highlight'>Find all conversations (Internal and External)</span>  related to a transaction at a single place</>,
};

const PConeStops = () => {
  const [selectedOption, setSelectedOption] = useState('Purchase Planning');

  return (
    <div className='PConeStops'>
      <div className="line-container-oss">
        <div className="line-text-oss">
          <h3 className='text-s1-oss'>
            <span className='Sp-Onoss'>One-stop solution</span> for all your Production needs
          </h3>
        </div>
      </div>
      <div>
        <div className="options-container-pcs">
          {Object.keys(optionImages).map((option) => (
            <div
              key={option}
              className={`option ${selectedOption === option ? 'selected-option' : ''}`}
              onClick={() => setSelectedOption(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </div>
        <div className="image-container-oss">
          <div className="text-overlay-oss">
            {optionTexts[selectedOption]}
          </div>
          <img src={optionImages[selectedOption]} alt={selectedOption} />
        </div>
      </div>
      <div className='banneroos-button'>
        <button className='PcButton1'>Get Started For Free</button> 
        <button className='Pcbutton2'>Book a Demo</button>
      </div>
    </div>
  )
}

export default PConeStops;

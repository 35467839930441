import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Pricing from './components/Pricing/Pricing';
import Inventory from './components/Inventory/Inventory';
import Production from './components/Production/Production';
import Material from './components/Material/Material';
import Salesmanage from './components/Salesmanage/Salesmanage';
import Purchase from './components/Purchase/Purchase';
import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/Contact-Us/ContactUs';
import OutTeam from './components/Our-Team/OutTeam';
import Navbars from './components/navbars/Navbars';


const App = () => {
  return (
    <Router>
      <Navbars/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inven" element={<Inventory />} />
        <Route path='/production' element={<Production/>}/>
        <Route path='/material' element={<Material/>}/>
        <Route path='/sales' element={<Salesmanage/>}/>
        <Route path='/purchase' element={<Purchase/>}/>
        <Route path="/pricing" element={<Pricing />} />
        <Route path='/aboutus' element={<AboutUs/>}/>
        <Route path='/contact' element={<ContactUs/>}/>
        <Route path='/Ourteam' element={<OutTeam/>}/>
      </Routes>
    </Router>
  );
}

export default App;

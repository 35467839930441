import React from 'react';
import "./Cbanner.css";
import contact from "../assets-cus/contact-us-cover.jpeg";

const Cbanner = () => {
  return (
    <div className='Cbanner'>
      <img className='Cbanner-img' src={contact} alt='Contact Us'/>
      <div className='Cbanner-overlay'></div>
      <div className='Cbanner-text'>
        <h1 className='cban-text1'>Get in Touch With Us</h1>
        <p className='cban-par1'>We'd love to hear from you. Here's how you can reach us -</p>
      </div>
      <div className='Cbanner-box'>
      <div className='cba-box1'>
                <h4>Enquiry</h4>
                <p className='cbabox-p'>Interested to get FiHD MRP for your business? Email us on the below email Id & our sales team will help you to know more & get started.</p>
                <p className='cbabox-p2'>sales@fihd.in</p>
            </div>
            <div className='cba-box2'>
                <h4>Email Us</h4>
                <p className='cbabox-p'>You can also drop your query on the below e-mail ID and we'll respond as soon as possible.</p>
                <p className='cbabox-p3'>contactus@fihd.in</p>
            </div>
            <div className='cba-box3'>
                <h4>Customer Support</h4>
                <p className='cbabox-p'>Wherever you're stuck, our superhero customer team has got you covered. Start a chat now.</p>
                <p className='cbabox-p3'>support@fihd.in</p>
            </div>
      </div>
    </div>
  );
}

export default Cbanner;

import React from 'react'
import Mmanage from '../material-page/Mmanage/Mmanage'
import Trusted from '../inventory-page/Trusted/Trusted'
import Footer from '../home-page/Footer/Footer'
import Tbanner from '../home-page/Tbanner/Tbanner'
import Integrates from '../inventory-page/Integrates/Integrates'
import Msoftware from '../production-page/Msoftware/Msoftware'
import MoneStop from '../material-page/MoneStop/MoneStop'

const Material = () => {
  return (
    <div className='Material'>
        <Mmanage/>
        <Trusted/>
        <MoneStop/>
        <Msoftware/>
        <Integrates/>
        <Tbanner/>
        <Footer/>
    </div>
  )
}

export default Material
import React from 'react';
import Pmanage from '../production-page/Pmanage/Pmanage';
import Trusted from '../inventory-page/Trusted/Trusted';
import Tbanner from '../home-page/Tbanner/Tbanner';
import Footer from '../home-page/Footer/Footer';
import Integrates from '../inventory-page/Integrates/Integrates';
import Msoftware from '../production-page/Msoftware/Msoftware';
import OneStops from '../production-page/OneStops/OneStops';

const Production = () => {
  return (
    <div className='Production'>
        <Pmanage/>
        <Trusted/>
        <OneStops/>
        <Msoftware/>
        <Integrates/>
        <Tbanner/>
        <Footer/>
    </div>
  )
}

export default Production
import React from 'react';
import logo1 from "../../../assets/Screenshot-2019-11-05-at-11.59.08-AM.png-removebg-preview.png";
import "./Tbanner.css";

const Tbanner = () => {
  return (
    <div className='T-banner'>
      <div className='Tz-img'>
        <img src={logo1} alt=''/>
      </div>
      <div className='Tz-text'>
        <p>FiHD MRP, developed by the team at Fi Havock Digital P. Ltd., is a powerful SaaS 
        solution designed to optimize the sales and purchase flow for your business. This 
        cloud-based platform offers an end-to-end digital transformation of your operations, 
        from customer inquiry to order fulfillment, ensuring a seamless and efficient process.
        With FiHD MRP, you can effortlessly manage your sales, purchases, and quotations in 
        a user-friendly interface tailored for SMEs. The software is easy to implement and free 
        to sign up, allowing you to streamline your processes and enhance productivity in just a week.</p>
      </div>
    </div>
  )
}

export default Tbanner

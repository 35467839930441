import React from 'react';
import Tbanner from '../home-page/Tbanner/Tbanner';
import Footer from '../home-page/Footer/Footer';
import "./Pricing.css";
import Plans from '../pricing-page/Plans/Plans';

const Pricing = () => {
  console.log('Rendering Pricing component'); // Added log
  return (
    <div className='Pricing'>
      <Plans/>
      <Tbanner />
      <Footer />
    </div>
  );
}

export default Pricing;

import React from 'react';
import "./PCmanage.css";

const PCmanage = () => {
  return (
    <div className='PCmanage'>
        <div className='Manage-text-pcm'>
            <h1 className='m-text1-pcm'>Purchase Management</h1>
            <h1 className='m-text2-pcm'>For Manufacturers</h1>
            <hr className='m-hr-pcm'/>
            <h3 className='m-text3-pcm'>MSMEs often face challenges in keeping optimum inventory due to inefficient purchase cycles. Not anymore!</h3>
        </div>
        <div className='Manage-text2-pcm'>
            <p className='p-mg-txpcm'>Purchase Order</p>
            <p className='p-mg-txpcm'>Quotation Comparison</p>
            <p className='p-mg-txpcm'>Quality Inspection Report (QIR)</p>
            <p className='p-mg-txpcm'>Bill Management</p>
        </div>
    </div>
  )
}

export default PCmanage
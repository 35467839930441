import React from 'react';
import "./Sitback.css";

const Sitback = () => {
  return (
    <div className='Sitback'>
      <div className='sit-box'>
        <div className='sit-box-text'>
            <h1>Sit back & relax, as we digitize you to become extraordinary!</h1>
        </div>
        <div className='sit-box-submit'>
            <input type='text' className='our-input-field' name='name ' placeholder='Phone Number'/>
            <input type='text' className='our-input-field' name='name ' placeholder='Email'/>
            <select
                name="option"
                className='our-select-field'
                required
              >
                <option value="" >Select your sector...</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
                <option value="Option 4">Option 4</option>
                <option value="Option 5">Option 5</option>
              </select>
              <div className='our-button-container'>
                <button className='our-button' type="submit">Get a Product Demo</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Sitback;

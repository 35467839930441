import React, { useState } from 'react';
import "./OneStop.css";
import inventory1 from "../assets-i/inventory1.png";
import inventory2 from "../assets-i/inventory2.png";
import inventory3 from "../assets-i/inventory3.png";
import inventory4 from "../assets-i/inventory4.png";
import inventory5 from "../assets-i/inventory5.png";
import inventory6 from "../assets-i/inventory6.png";

const optionImages = {
  'stock-automation': inventory1,
  valuation: inventory2,
  history: inventory3,
  ageing: inventory4,
  alerts: inventory5,
  'barcode-tracking': inventory6
};

const optionTexts = {
  'stock-automation': <><span className='highlight'>Automate the inflow and outflow</span> of your products and achieve a stock accuracy of 99%</>,
  valuation: <>Calculate the <span className='highlight'>real-time valuation</span> of your stock using different price methods</>,
  history: <><span className='highlight'>Track and Download</span> the entire history (i.e. who made the changes, by how much, when and where) of all your stock movements</>,
  ageing: <><span className='highlight'>Get Real-time visibility of your stock's age</span> and continuously optimise your inventory turnover</>,
  alerts: <>Set-up <span className='highlight'>15+ types of automatic alerts</span> over WhatsApp and Email to always stay in control of your business</>,
  'barcode-tracking': <>Track all the stock movements so you can <span className='highlight'>back-trace specific raw materials</span> used to produce specific finished goods</>
};

const OneStop = () => {
  const [selectedOption, setSelectedOption] = useState('stock-automation');

  return (
    <div className='Onestop'>
      <div className="line-container-os">
        <div className="line-text-os">
          <h3 className='text-s1-os'>
            <span className='Sp-On'>One-stop solution</span> for all your inventory management needs
          </h3>
        </div>
      </div>
      <div>
        <div className="options-container">
          {Object.keys(optionImages).map((option) => (
            <div
              key={option}
              className={`option ${selectedOption === option ? 'selected-optioni' : ''}`}
              onClick={() => setSelectedOption(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </div>
        <div className="image-container">
          <div className="text-overlay">
            {optionTexts[selectedOption]}
          </div>
          <img src={optionImages[selectedOption]} alt={selectedOption} />
        </div>
      </div>
      <div className='banneros-button'>
        <button className='button1os'>Get Started For Free</button> 
        <button className='button2os'>Book a Demo</button>
      </div>
    </div>
  )
}

export default OneStop;

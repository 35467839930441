import React, { useState } from 'react';
import './Navbars.css'; // Updated CSS file import
import logo from "../../assets/Screenshot-2019-11-05-at-11.59.08-AM.png.png";
import { Link } from 'react-router-dom';
import { PiLineVerticalThin } from 'react-icons/pi';
import Popup from '../Popup-Banner/Popup';
import { FaBars } from 'react-icons/fa';

const CustomNavbars = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = (menu) => {
    setActiveDropdown(activeDropdown === menu ? null : menu);
  };

  const closeDropdown = () => {
    setActiveDropdown(null); // Close the dropdown
    setMobileMenuOpen(false); // Close the mobile menu
  };

  return (
    <nav className="CustomNavbars">
      <div className="CustomNavbars-container">
        <div className="CustomNavbars-logo">
          <Link to="/">
            <img className="custom-logo" src={logo} alt="Home"/>
          </Link>
        </div>
        <ul className={`CustomNavbars-menu ${isMobileMenuOpen ? 'custom-active' : ''}`}>
          <li className="CustomNavbars-item custom-product-item" onClick={() => toggleDropdown('product')}>
            <a href="#product" className="CustomNavbars-link">Our Product</a>
            <ul className={`CustomNavbarsdropdown-menu ${activeDropdown === 'product' ? 'custom-open' : ''}`}>
              <p className="CustomNavbarsdropdown-head">Solution</p>
              <li><Link className="CustomNavbarsdropdown-item" to="/sales" onClick={closeDropdown}>Sales Management</Link></li>
              <li><Link className="CustomNavbarsdropdown-item" to="/purchase" onClick={closeDropdown}>Purchase Management</Link></li>
            </ul>
          </li>
          <li className="CustomNavbars-item custom-product-item" onClick={() => toggleDropdown('resources')}>
            <a href="#resources" className="CustomNavbars-link">Resources</a>
            <ul className={`CustomNavbarsdropdown-menu ${activeDropdown === 'resources' ? 'custom-open' : ''}`}>
              <p className="CustomNavbarsdropdown-head">Company</p>
              <li><Link className="CustomNavbarsdropdown-item" to="/aboutus" onClick={closeDropdown}>About Us</Link></li>
              <li><Link className="CustomNavbarsdropdown-item" to="/contact" onClick={closeDropdown}>Contact Us</Link></li>
            </ul>
          </li>
          <li className="CustomNavbars-item">
            <Link className="CustomNavbars-link custom-a1" to="/pricing" onClick={closeDropdown}>Pricing</Link>
          </li>
        </ul>
        <ul className="custom-desktop-only">
          <PiLineVerticalThin />
          <li><a className="custom-a2" href="/Login">Login</a></li>
          <PiLineVerticalThin />
          {/*<li><a className="custom-a2" href="#" onClick={handlePopup}>Book a demo</a></li>*/}
        </ul>
        <button className="custom-button">Free Sign Up</button>
        <div className="custom-mobile-menu-icon" onClick={toggleMobileMenu}>
          <FaBars />
        </div>
      </div>
      <Popup show={showPopup} onClose={handlePopup} />
    </nav>
  );
};

export default CustomNavbars;

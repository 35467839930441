import React, { useState } from 'react';
import { FaHeart } from "react-icons/fa";
import Icon from "../../home-page/assets-h/icon.png";
import "./Footer.css";

const Footer = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className='Footer'>
      <div className='Footer-header'>
        <h1>Made in India, with <FaHeart /></h1>
        <h2><span className='f-text'>Grow with us. </span>Transform with FiHD MRP.</h2>
      </div>
      <div className='Footer-content'>
        <div 
          className={`Footer-section ${activeSection === 'tranzact' ? 'active' : ''}`} 
          onClick={() => toggleSection('tranzact')}
        >
          <h3>FiHD MRP</h3>
          <hr/>
          <ul>
            <li><a href='#'>Overview</a></li>
            <li><a href='#'>Pricing</a></li>
            <li><a href='#'>Support</a></li>
          </ul>
        </div>
        <div 
          className={`Footer-section ${activeSection === 'features' ? 'active' : ''}`} 
          onClick={() => toggleSection('features')}
        >
          <h3>Our Solutions</h3>
          <hr/>
          <ul>
            {/*<li><a href='#'>Inventory Management</a></li>
            <li><a href='#'>Production Management</a></li>
            <li><a href='#'>Material Resource</a></li>*/}
            <li><a href="#">Slaes Management</a></li>
            <li><a href="#">Purchase Management</a></li>
          </ul>
        </div>
        <div 
          className={`Footer-section ${activeSection === 'templates' ? 'active' : ''}`} 
          onClick={() => toggleSection('templates')}
        >
          <h3>Templates</h3>
          <hr/>
          <ul>
            <li><a href='#'>E-Way Bill</a></li>
            <li><a href='#'>E-Invoice</a></li>
            <li><a href='#'>GST Invoice</a></li>
            {/*<li><a href='#'>Export Invoice</a></li>
            <li><a href='#'>Bill of Supply</a></li>*/}
          </ul>
        </div>
        <div 
          className={`Footer-section ${activeSection === 'company' ? 'active' : ''}`} 
          onClick={() => toggleSection('company')}
        >
          <h3>Company</h3>
          <hr/>
          <ul>
            <li><a href="#">About Us</a></li>
            <li><a href="#">Our Team</a></li>
            <li><a href="#">Careers</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
        <div className='Footer-address'>
          <h3>Address</h3>
          <p>Mumbai, Maharashtra, India</p>
          {/*<h3>Contact :- <span className='cg-text'>+91 9892768644</span></h3>*/}
          <h3>Gmail :- <span className='cg-text'>sales@fihd.in</span></h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react';
import Sponsorimg from "../assets-i/Sponsor.png";
import "./Trusted.css";

const Trusted = () => {
    const images = [Sponsorimg, Sponsorimg, Sponsorimg, Sponsorimg, Sponsorimg, Sponsorimg, Sponsorimg, Sponsorimg];
  return (
    <div className='Trustedi'>
      <div className="line-containeri">
        <div className="line-texti">
          <h3 className='text-s1i'>
              Trusted by 10,000+ Indian MSME Manufacturers
          </h3>
        </div>
      </div>
      <div className="sponsor-imagesi">
        {images.map((img, index) => (
          <img key={index} src={img} alt={`Sponsor ${index + 1}`} className="sponsor-imgi"/>
        ))}
      </div>
    </div>
  )
}

export default Trusted

import React from 'react';
import "./Integrates.css"
import tally from "../assets-i/tally..jpg";
import excel from "../assets-i/excel.jpg";
import aftership from "../assets-i/aftership.jpg"

const Integrates = () => {
  return (
    <div className='integrate'>
      <div className="line-containeri">
        <div className="line-texti">
          <h3 className='text-si'>
            TranZact Integrates with
          </h3>
        </div>
      </div>
      <div className='inte-containers'>
        <div className='box1i'>
            <img src={tally} alt=''/>
            <h3 className='s-texti'>Tally</h3>
            <p className=''>Collaborate & sync with your team</p>
            <p className=''>to work on Tally.</p>
        </div>
        <div className='box1i'>
            <img src={excel} alt=''/>
            <h3 className='s-texti'>Excel </h3>
            <p className=''>Connect TranZact with custom</p>
            <p className=''>excel spreadsheets.</p>
        </div>
        <div className='box1i'>
            <img src={aftership} alt=''/>
            <h3 className='s-texti'>AfterShip</h3>
            <p className=''>Provide the best logistics</p>
            <p className=''>experience to all your customers!</p>
        </div>
      </div>
      <button className='s-buttoni'>Get Started For Free</button>
    </div>
  )
}

export default Integrates;
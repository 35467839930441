import React from 'react';
import "./Believe.css";

const Believe = () => {
  return (
    <div className='Believe'>
        <div className='bel-header'>
            <h2 className='bel-text1'>What We Believe In</h2>
            <p className='bel-text2'>The greatest purpose of technology is to improve the lives of all, in the society.</p>
        </div>
        <div className='bel-container'>
            <div className='bel-box1'>
                <p>01</p>
                <p className='betbox-p'>Our users are at the center of our world.</p>
            </div>
            <div className='bel-box2'>
                <p>02</p>
                <p className='betbox-p'>It's always day 1 for us.</p>
            </div>
            <div className='bel-box3'>
                <p>03</p>
                <p className='betbox-p'>Chasing excellence, with focus.</p>
            </div>
        </div>
    </div>
  )
}

export default Believe
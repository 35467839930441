import React from 'react';
import "./Mission.css";
import missimg from "../assets-au/about-us-mission-cover.png";

const Mission = () => {
  return (
    <div className='Mission'>
        <div className='Mission-text'>
            <h3 className='miss-head'>Our Mission</h3>
            <p className='miss-text1'>At Fi Havock Digital P. Ltd., our mission with FiHD MRP is to empower 
              businesses by making digital transformation simple and accessible. We understand the unique 
              challenges faced by SMEs, which is why we’ve developed a solution that streamlines the entire 
              sales and purchase flow with effortless automation.</p>
            <p className='miss-text2'>Our focus is on simplicity. With over 40 years of combined experience 
              working with SMEs, we've learned that while automation is crucial, what our customers value 
              even more is automation made simple. That's why every feature of FiHD MRP is designed to solve 
              real business problems in the most straightforward, efficient way possible.</p>
        </div>
        <div className='Mission-img'>
            <img src={missimg} alt=''/>
        </div>
    </div>
  )
}

export default Mission;
import React, { useState } from 'react';
import "./SoneStop.css";
import euiry from "../assets-s/enquiry-mgt-snap.png";
import trans from "../assets-s/trans-time-snap.png";
import team from "../assets-s/team-colab-snap.png";
import eway from "../assets-s/eway-bill-snap.png";


const optionImages = {
  'Equiry Management': euiry,
  'Transaction Timeline': trans,
  'Team Collaboration': team,
  'e-Way Bill & e-Invoicing': eway,
};

const optionTexts = {
  'Equiry Management':<><span className='highlight'> Track the real-time status</span> of all your sales enquiries at a single place</>,
  'Transaction Timeline': <><span className='highlight'>Track the entire timeline </span> of your transactions (i.e OC, Proforma, ARV, Invoice, Challan etc) at a single place.</>,
  'Team Collaboration': <>Find all<span className='highlight'> conversations </span> (Internal and External) related to a transaction at a single place</>,
  'e-Way Bill & e-Invoicing': <>Easily generate e-Invoice and e-Way Bills and stay compliant with government norms</>,
};

const SoneStops = () => {
  const [selectedOption, setSelectedOption] = useState('Equiry Management');

  return (
    <div className='SoneStops'>
      <div className="line-container-oss">
        <div className="line-text-oss">
          <h3 className='text-s1-oss'>
            <span className='Sp-Onoss'>One-stop solution</span> for all your Production needs
          </h3>
        </div>
      </div>
      <div>
        <div className="options-container-oss">
          {Object.keys(optionImages).map((option) => (
            <div
              key={option}
              className={`option ${selectedOption === option ? 'selected-option' : ''}`}
              onClick={() => setSelectedOption(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </div>
        <div className="image-container-oss">
          <div className="text-overlay-oss">
            {optionTexts[selectedOption]}
          </div>
          <img src={optionImages[selectedOption]} alt={selectedOption} />
        </div>
      </div>
      <div className='banneros-button'>
        <button className='button1os'>Get Started For Free</button> 
        <button className='button2os'>Book a Demo</button>
      </div>
    </div>
  )
}

export default SoneStops;

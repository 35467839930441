import React from 'react'
import PCmanage from '../purchase-page/PCmanage/PCmanage'
import Trusted from '../inventory-page/Trusted/Trusted'
import Tbanner from '../home-page/Tbanner/Tbanner'
import Footer from '../home-page/Footer/Footer'
import Msoftware from '../production-page/Msoftware/Msoftware'
import PConeStops from '../purchase-page/PConeStop/PConeStop'
import Integrates from '../inventory-page/Integrates/Integrates'

const Purchase = () => {
  return (
    <div>
        <PCmanage/>
        <PConeStops/>
        <Msoftware/>
        <Tbanner/>
        <Footer/>
    </div>
  )
}

export default Purchase
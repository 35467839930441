import React from 'react'
import Ourbanner from '../our-team-page/Ourbanner/Ourbanner'
import Tbanner from '../home-page/Tbanner/Tbanner'
import Footer from '../home-page/Footer/Footer'
import Sitback from '../aboutus-page/Sit-back/Sitback'
import Talent from '../our-team-page/Talent/Talent'
import Team from '../our-team-page/Team/Team'

const OutTeam = () => {
  return (
    <div>
      <Ourbanner/>
      <Talent/>
      <Team/>
      <Sitback/>
      <Tbanner/>
      <Footer/>
    </div>
  )
}

export default OutTeam

import React from 'react';
import "./Software.css";
import execl from "../assets-i/excel-icon.png";
import stock from "../assets-i/stock-mgt-icon.png";
import reduce from "../assets-i/instock-icon.png";
import chat from "../assets-i/chat-support-icon.png";
import alerts from "../assets-i/stock-alert-icon.png";
import reports from "../assets-i/data-reports-icon.png";
import mobile from "../assets-i/mobile-mgt-icon.png";

const Software = () => {
  const images = [
    { src: execl, text: 'Easy as MS Excel' },
    { src: stock, text: (<>Free stock <br/> management</>)},
    { src: reduce, text: 'Reduce wastage' },
    { src: chat, text: 'Instant chat support' },
    { src: alerts, text: 'Set-up 15+ alerts' },
    { src: reports, text: 'Advanced Reports'},
    { src: mobile, text: (<>Manage stock on <br/> mobile app</>) },
  ];

  return (
    <div className='software'>
      <div className="line-container-os">
        <div className="line-text-os">
          <h3 className='text-s1-os'>
            <span className='Sp-On'>One-stop solution</span> for all your inventory management needs
          </h3>
        </div>
      </div>
      <div className="image-grid">
        {images.map((image, index) => (
          <div className="image-item" key={index}>
            <img src={image.src} alt={`Image ${index + 1}`} />
            <p>{image.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Software;

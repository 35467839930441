import React from 'react';
import "./Footersec.css";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footersec = () => {
  return (
    <div className='Footersec'>
      <div className='Footersec-text'>
        <h3>© 2024 FI HAVOCK DIGITAL P. Ltd. Privacy Policy Legal</h3>
      </div>
      {/*<div className='Footersec-icons'>
      <Link className='footer-icon1' to="#"><FaLinkedin /></Link>
      <Link className='footer-icon1' to="#"><FaInstagram /></Link>
      <Link className='footer-icon1' to="#"><FaXTwitter /></Link>
      <Link className='footer-icon1' to="#"><FaFacebookF /></Link>
      <Link className='footer-icon1' to="#"><FaYoutube /></Link>
      </div>*/}
    </div>
  )
}

export default Footersec

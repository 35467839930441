import React, { useState } from 'react';
import "./OneStops.css";
import multi from "../assets-p/multi-bom-snap.png";
import process from "../assets-p/process-trk-snap.png";
import costing from "../assets-p/costing-snap.png";
import scrap from "../assets-p/scrap-trk-snap.png";
import sub from "../assets-p/subcontract-snap.png";


const optionImages = {
  'Multi-Level BOM': multi,
  'Process Tracking': process,
  Costing: costing,
  'Stop Tracking': scrap,
  Subcontracting: sub,
};

const optionTexts = {
  'Multi-Level BOM':<>Upload<span className='highlight'> complex recipes</span> (e.g. BOMs inside BOMs) of your finished goods easily</>,
  'Process Tracking': <><span className='highlight'>Track the status </span> of all your production processes at a single place to never miss your deadlines</>,
  Costing: <>Easily calculate the<span className='highlight'> net cost</span> of your Bill of Materials and Production Processes to continuously reduce it</>,
  'Stop Tracking': <>Efficiently track the<span className='highlight'> scrap wastage</span> and accurately measure its costing.</>,
  Subcontracting: <><span className='highlight'>Need to outsource</span>  a part of your production? Track the status and measure the costing of your subcontracts seamlessly</>,
};

const OneStops = () => {
  const [selectedOption, setSelectedOption] = useState('Multi-Level BOM');

  return (
    <div className='OneStops'>
      <div className="line-container-oss">
        <div className="line-text-oss">
          <h3 className='text-s1-oss'>
            <span className='Sp-Onoss'>One-stop solution</span> for all your Production needs
          </h3>
        </div>
      </div>
      <div>
        <div className="options-container-oss">
          {Object.keys(optionImages).map((option) => (
            <div
              key={option}
              className={`option ${selectedOption === option ? 'selected-optionp' : ''}`}
              onClick={() => setSelectedOption(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </div>
        <div className="image-container-oss">
          <div className="text-overlay-oss">
            {optionTexts[selectedOption]}
          </div>
          <img src={optionImages[selectedOption]} alt={selectedOption} />
        </div>
      </div>
      <div className='banneros-button'>
        <button className='button1os'>Get Started For Free</button> 
        <button className='button2os'>Book a Demo</button>
      </div>
    </div>
  )
}

export default OneStops;

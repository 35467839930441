import React from 'react';
import "./Ourbanner.css";

const Ourbanner = () => {
  return (
    <div className='Ourbanner'>
      <div className='Our-text'>
        <h1 className='our-text1'>Let's grow together</h1>
        <p className='our-text2'>
          Like all great companies, we always strive to build a 
          team of rockstars, inculcate incredibly flexible culture, 
          design innovative products with scalable technology, 
          and build a highly valuable and impactful business. If 
          you share the same vision, you have come to the right place.
        </p>
      </div>
      <div className='Our-video'>
        <iframe
          width="100%"
          height="100%"
          className='videoour'
          src="https://www.youtube.com/embed/VVbxv_7IjgE"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Ourbanner;

import React, { useState, useEffect } from 'react';
import './Popup.css';

const Popup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto'; // Allow scrolling
    }
    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrolling is restored on unmount
    };
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to server)
    console.log(formData);
    onClose(); // Close the popup after submission
  };

  return (
    <div className={`popup ${show ? 'show' : ''}`}>
      <div className='popup-content'>
        <div className='harder-1'>
          <button className='close' onClick={onClose}>×</button>
          <h2>Join me for a demo</h2>
          <div className='img-container'>
            <img src='person' alt='' className='profile-img'/>
            <div className='text-container'>
              <p className='img-text'>Sharad Sen Sharma</p>
              <p className='img-text2'>Co-Founder at TranZact</p>
            </div>
          </div>
          <p className='pop-text'>Please fill the below fields for me to personalize your Product Demo Experience</p>
        </div>
        <hr/>
        <div className='harder-2'>
          <form onSubmit={handleSubmit}>
            <label>
              Name*:
              <input
                type="text"
                className='input-field'
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Company*:
              <input
                type="text"
                className='input-field'
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email*:
              <input
                type="email"
                className='input-field'
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Phone Number*:
              <input
                type="tel"
                className='input-field'
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Sector*:
              <select
                name="option1"
                className='select-field'
                value={formData.option1}
                onChange={handleChange}
                required
              >
                <option value="">Select your sector...</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
                <option value="Option 4">Option 4</option>
                <option value="Option 5">Option 5</option>
              </select>
            </label>
            <label>
              Industry*:
              <select
                name="option2"
                className='select-field'
                value={formData.option2}
                onChange={handleChange}
                required
              >
                <option className='op-main' value="">Select your industry...</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
                <option value="Option 4">Option 4</option>
                <option value="Option 5">Option 5</option>
              </select>
            </label>
            <p className='la-text'>you agree to the <span className='sp-text'>Terms of Service</span></p>
            <div className='pop-button-container'>
                <button className='pop-button' type="submit">Get a Product Demo</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Popup;

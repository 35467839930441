import React from 'react';
import "./Smanage.css";

const Smanage = () => {
  return (
    <div className='Smanage'>
        <div className='Manage-text-sm'>
            <h1 className='m-text1-sm'>Sales Management</h1>
            <h1 className='m-text2-sm'>For Manufacturers</h1>
            <hr className='m-hr-sm'/>
            <h3 className='m-text3-sm'>MSMEs often face challenges in managing enquiries and communicating between various departments. Not anymore!</h3>
        </div>
        <div className='Manage-text2-sm'>
            <p className='p-mg-txsm'>Enquiry Management</p>
            <p className='p-mg-txsm'>Quotations</p>
            <p className='p-mg-txsm'>Sales Order</p>
            <p className='p-mg-txsm'>e-Way Bill & e-Invoicing</p>
            <p className='p-mg-txsm'>Third-Party Integration</p>
        </div>
    </div>
  )
}

export default Smanage
import React from 'react';
import "./Team.css";

const Team = () => {
  return (
    <div className='Team'>
        <div className='Team-text'>
            <h1 className='teamtext1'>Out Team</h1>
            <p className='teamtext2'>TranZact is a full-service digital platform that combines the top-level strategy with implementation and technological capabilities.</p>
        </div>
    </div>
  )
}

export default Team;
import React from 'react'
import Cbanner from '../contact-us-page/Cbanner/Cbanner'
import Tbanner from '../home-page/Tbanner/Tbanner'
import Footer from '../home-page/Footer/Footer'
import Map from '../contact-us-page/Map/Map'

const ContactUs = () => {
  return (
    <div>
      <Cbanner/>
      <Tbanner/>
      <Footer/>
    </div>
  )
}

export default ContactUs

import React, { useState } from 'react';
import { FaPlus, FaMinus } from "react-icons/fa6";
import "./FAQs.css";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What can I do with FiHD MRP?",
      answer: "With FiHD MRP, you can effortlessly manage all core business processes related to sales and purchases. Our SaaS solution provides customized automation for Sales, Purchase Orders, and Quotations, with seamless integration with popular tools like Tally and Excel. Streamline your operations and enhance efficiency with FiHD MRP, developed by Fi Havock Digital P. Ltd."
    },
    {
      question: "Is my data safe on FiHD MRP?",
      answer: "FiHD MRP is built on the secure AWS cloud platform by a team of Indian engineers, specifically designed to meet the needs of Indian SMEs. Our solution ensures 100% data security, providing you with complete peace of mind and robust data protection for all your sales and purchase operations."
    },
    {
      question: "Can I use FiHD MRP for free?",
      answer: "Yes, FiHD MRP offers a comprehensive solution for managing the entire sales and purchase flow for SMEs. Developed by Fi Havock Digital P. Ltd., it provides end-to-end automation and key business insights to optimize your operations and drive growth."
    },
    {
      question: "Who all can use FiHD MRP?",
      answer: "FiHD MRP is designed specifically for Indian SMEs to manage their sales and purchase processes with ease. With its intuitive user interface, the software is simple to use and can be operated effortlessly by anyone on your team."
    },
    {
      question: "Can I tarck my inventory on FiHD MRP?",
      answer: "FiHD MRP provides a robust solution for managing sales and purchase processes, specifically designed for SMEs. It offers end-to-end automation of your sales and purchase flow, delivering key insights that help you make informed business decisions and streamline your operations."
    },{
        question:"Can I use FiHD MRP on a mobile app?",
        answer:"Yes, FiHD MRP also comes with a mobile app, allowing you to manage your sales and purchase processes on the go!"
    }
  ];

  return (
    <div className='FAQs-container'>
      <div className="line-container">
        <div className="line-text">
          <h3 className='text-s'>
            FAQs
          </h3>
        </div>
      </div>
      <div className='FAQs-info'>
        {faqs.map((faq, index) => (
          <div className={`i-box ${openIndex === index ? 'open' : ''}`} onClick={() => toggleFAQ(index)} key={index}>
            <p className='p1text'>{openIndex === index ? <FaMinus className='bar'/> : <FaPlus className='bar'/>} {faq.question}</p>
            {openIndex === index && <p className='ans'>{faq.answer}</p>}
          </div>
        ))}
      </div>
      <button className='button11'>Get Started For Free</button>
    </div>
  );
}

export default FAQs;

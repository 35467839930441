import React from 'react'
import Smanage from '../salesmanage-page/Smanage/Smanage'
import Trusted from '../inventory-page/Trusted/Trusted'
import Footer from '../home-page/Footer/Footer'
import Integrates from '../inventory-page/Integrates/Integrates'
import Msoftware from '../production-page/Msoftware/Msoftware'
import Tbanner from '../home-page/Tbanner/Tbanner'
import SoneStops from '../salesmanage-page/SoneStop/SoneStop'

const Salesmanage = () => {
  return (
    <div>
        <Smanage/>
        <SoneStops/>
        <Msoftware/>
        <Tbanner/>
        <Footer/>
    </div>
  )
}

export default Salesmanage
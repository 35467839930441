import React from 'react';
import "./Aubanner.css"
import Auimg from "../assets-au/about-us-team-cover-new.jpeg";

const Aubanner = () => {
  return (
    <div className='Aubanner'>
        <div className='Aubanner-text'>
            <h1 className='au-text1'>Simplifying Automation for </h1>
            <h1 className='au-text2'>SME Manufacturers</h1>

        </div>
        {/*<div className='Aubanner-img'>
            <img className='au-img' src={Auimg} alt=''/>
        </div>*/}
    </div>
  )
}

export default Aubanner
import React from 'react';
import "./Plans.css";

const Plans = () => {
  return (
    <div className='Plans'>
      <div className="line-container-pl">
        <div className="line-text-pl">
          <h3 className='text-s-pl'>
            Pricing plans tailored for all types of MSME businesses
          </h3>
        </div>
      </div>
      <div className='Plans-table'>
      <table className="plans-table">
        <thead>
          <tr>
            <th></th>
            <th className='th-head1'>
                Micro Plan
                <p className='p-main'>₹ 0</p>
                <button className='ex-bt'>Signup Now</button>
            </th>
            <th className='th-head2'>
                Small Plan
                <p className='p-main'>₹ 3,999<span className='th-p-text'> / Per Month</span></p>
                <p className='th-p-tex'>( Billed Yearly )</p>
                <p className='th-p-text2'>Everything in<span className='th-p-text3'> Micro Plan</span></p>
                <button className='pl-button'>Start a Free Trial</button>
            </th>
            <th className='th-head3'>
                Medium Plan
                <p className='p-main'>₹ 9,999<span className='th-p-text'> / Per Month</span></p>
                <p className='th-p-tex'>( Billed Yearly or Quarterly )</p>
                <p className='th-p-text2'>Everything in<span className='th-p-text3'> Small Plan</span></p>
                <button className='pl-button'>Start a Free Trial</button>
            </th>
            <th className='th-head2'>
                Enterprise Plan
                <p className='p-main'>₹ 29,999<span className='th-p-text'> / Per Month</span></p>
                <p className='th-p-tex'>( Billed Yearly or Quarterly )</p>
                <p className='th-p-text2'>Everything in<span className='th-p-text3'> Medium Plan</span></p>
                <button className='pl-button'>Talk to Sales</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='td-m-text'>⭐️ Sales & Purchase</td>
            <td className='td-r-text'>
              <p>- Advanced Lead Management</p>
              <p>- 30 Transactions/month</p>
            </td>
            <td className='td-r-text'>- Delivery & Payment Reminders</td>
            <td className='td-r-text'>
                <p>- e-Invoicing</p>
                <p>- Multi-currency Support</p>
            </td>
            <td className='td-r-text'>- Easyecomm Integration</td>
          </tr>
          <tr>
            <td className='td-m-text'>⭐️ Inventory & Planning</td>
            <td className='td-r-text'>
                <p>- Inventory Tracking</p>
                <p>- Stock History</p>
            </td>
            <td className='td-r-text'>- Stock Valuation</td>
            <td className='td-r-text'>
                <p>- Inventory Ageing</p>
                <p>- Planning (MRP + Indent)</p>
            </td>
            <td className='td-r-text'>- Batch Tracking (Barcode)</td>
          </tr>
          <tr>
            <td className='td-m-text'>⭐️ Production Management</td>
            <td></td>
            <td className='td-r-text'>
                <p>- Multi-level Bill of Material</p>
                <p>- Single-step Production</p>
            </td>
            <td className='td-r-text'>
                <p>- Multi-step Production</p>
                <p>- Production Costing</p>
            </td>
            <td className='td-r-text'>- Customised Reports</td>
          </tr>
          <tr>
            <td className='td-m-text'>⭐️ Access Management & Integrations</td>
            <td className='td-r-text'>- WhatsApp Integration</td>
            <td className='td-r-text'>
                <p>- Tally/Busy/Zoho Integration</p>
                <p>- Gmail Integration</p>
            </td>
            <td className='td-r-text'>- Access Management</td>
            <td className='td-r-text'>
                <p>- Store-level Permissions</p>
                <p>- IP Whitelisting</p>
            </td>
          </tr>
          <tr>
            <td className='td-m-text'>⭐️ BI and Reports</td>
            <td className='td-r-text'>
                <p>- 10+ Reports</p>
                <p>- Performance Digest</p>
            </td>
            <td className='td-r-text'>
                <p>- Business Intelligence</p>
                <p>- 15+ Automatic Alerts</p>
            </td>
            <td className='td-r-text'>
                <p>- 65+ Reports</p>
                <p>- Document Approvals</p>
            </td>
            <td className='td-r-text'>- Google Sheets MIS Integration</td>
          </tr>
          <tr className='special-row'>
            <td className='td-m-text'>⭐️ Users</td>
            <td className="special-cell">Up to 5 User</td>
            <td className="special-cell">Up to 5 User</td>
            <td className="special-cell">Up to 10 User</td>
            <td className="special-cell">At least 15 Users</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  )
}

export default Plans;
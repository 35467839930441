import React from 'react';
import "./Pmanage.css";

const Pmanage = () => {
  return (
    <div className='Pmanage'>
        <div className='Manage-text-pm'>
            <h1 className='m-text1-pm'>Production Management</h1>
            <h1 className='m-text2-pm'>For Manufacturers</h1>
            <hr className='m-hr-pm'/>
            <h3 className='m-text3-pm'>Indian MSMEs often struggle with inefficient production cycles, high wastage and last-minute delays. But not anymore!</h3>
        </div>
        <div className='Manage-text2-pm'>
            <p className='p-mg-txpm'>Multi-level BOM</p>
            <p className='p-mg-txpm'>Process Tracking</p>
            <p className='p-mg-txpm'>Production Costing</p>
            <p className='p-mg-txpm'>Subcontracting</p>
            <p className='p-mg-txpm'>Scrap Tracking</p>
        </div>
    </div>
  )
}

export default Pmanage
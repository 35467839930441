import React from 'react'
import Footer from '../home-page/Footer/Footer'
import Tbanner from '../home-page/Tbanner/Tbanner'
import Manage from '../inventory-page/Manage/Manage'
import Trusted from '../inventory-page/Trusted/Trusted'
import OneStop from '../inventory-page/OneStop/OneStop'
import Integrates from '../inventory-page/Integrates/Integrates'
import Software from '../inventory-page/Software/Software'

const Inventory = () => {
  return (
    <div className='Inventory'>
        <Manage/>
        <Trusted/>
        <OneStop/>
        <Software/>
        <Integrates/>
        <Tbanner/>
        <Footer/>
    </div>
  )
}

export default Inventory
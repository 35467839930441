import React, { useState } from 'react';
import "./MoneStop.css";
import one from "../assets-m/one-click-mrp-snap.png";
import purchase from "../assets-m/purchase-req-snap.png"
import made from "../assets-m/made-order-snap.png";


const optionImages = {
  'One-click MRP Reports': one,
  'Purchase Requisitions': purchase,
  'Made-to-Order Planning': made,
};

const optionTexts = {
  'One-click MRP Reports':<><span className='highlight'> Create MRP reports in few seconds</span>  to accurately measure your purchase requirements</>,
  'Purchase Requisitions': <><span className='highlight'>Create and Track </span> Purchase Requisitions easily and always maintain optimum stock</>,
  'Made-to-Order Planning': <>Plan your purchase requirements for a <span className='highlight'> specific set</span> of production orders</>,
};

const MoneStop = () => {
  const [selectedOption, setSelectedOption] = useState('One-click MRP Reports');

  return (
    <div className='MoneStop'>
      <div className="line-container-oss">
        <div className="line-text-oss">
          <h3 className='text-s1-oss'>
            <span className='Sp-Onoss'>One-stop solution</span> for all your Production needs
          </h3>
        </div>
      </div>
      <div>
        <div className="options-container-oss">
          {Object.keys(optionImages).map((option) => (
            <div
              key={option}
              className={`option ${selectedOption === option ? 'selected-optionm' : ''}`}
              onClick={() => setSelectedOption(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </div>
        <div className="image-container-oss">
          <div className="text-overlay-oss">
            {optionTexts[selectedOption]}
          </div>
          <img src={optionImages[selectedOption]} alt={selectedOption} />
        </div>
      </div>
      <div className='banneros-button'>
        <button className='button1os'>Get Started For Free</button> 
        <button className='button2os'>Book a Demo</button>
      </div>
    </div>
  )
}

export default MoneStop;

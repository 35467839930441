// components/Home/Home.js
import React, { useState, useEffect } from 'react';
import Banner from '../home-page/Banner/Banner';
import Sponsor from '../home-page/Sponsor/Sponsor';
import Solution from '../home-page/Solution/Solution';
import Security from '../home-page/Security/Security';
import FAQs from '../home-page/FAQs/FAQs';
import Tbanner from '../home-page/Tbanner/Tbanner';
import Footer from '../home-page/Footer/Footer';
import Sales from '../home-page/Sales/Sales';
import Customers from '../home-page/Customers/Customers';
import Footersec from '../home-page/Footersec/Footersec';


const Home = () => {

  return (
    <div className='Home'>
      <Banner />
      <Sales />
      <Security />
      <FAQs />
      <Tbanner />
      <Footer />
      <Footersec/>
    </div>
  );
}

export default Home;
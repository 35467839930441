import React from 'react';
import Aubanner from '../aboutus-page/Aubanner/Aubanner';
import Tbanner from '../home-page/Tbanner/Tbanner';
import Footer from '../home-page/Footer/Footer';
import Mission from '../aboutus-page/Mission/Mission';
import Story from '../aboutus-page/Story/Story';
import Believe from '../aboutus-page/Believe/Believe';
import Our from '../aboutus-page/Our/Our';
import Sitback from '../aboutus-page/Sit-back/Sitback';

const AboutUs = () => {
  return (
    <div>
      <Aubanner/>
      <Mission/>
      <Story/>
      <Believe/>
      <Tbanner/>
      <Footer/>
    </div>
  )
}

export default AboutUs

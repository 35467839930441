import React from 'react';
import "./Mmanage.css";

const Mmanage = () => {
  return (
    <div className='Mmanage'>
        <div className='Manage-text-mm'>
            <h1 className='m-text1-mm'>Material Resource Planning</h1>
            <h1 className='m-text2-mm'>For Manufacturers</h1>
            <hr className='m-hr-mm'/>
            <h3 className='m-text3-mm'>Indian MSMEs often end up placing duplicate orders or run into production delays due to low stock problems. But not anymore!</h3>
        </div>
        <div className='Manage-text2-mm'>
            <p className='p-mg-txmm'>One-click MRP Reports</p>
            <p className='p-mg-txmm'>Purchase Planning</p>
            <p className='p-mg-txmm'>Requisition Tracking</p>
            <p className='p-mg-txmm'>Indent to Inward</p>
            <p className='p-mg-txmm'>Transaction Timeline</p>
        </div>
    </div>
  )
}

export default Mmanage
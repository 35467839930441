import React from 'react';
import "./Story.css";
import story from "../assets-au/about-us-story-cover.png";

const Story = () => {
  return (
    <div className='Story'>
        <h2><span className='story-sp-t'>The Story</span> Behind FiHD MRP</h2>
        <div className='Story-content'>
            <div className='Story-img'>
                <img src={story} alt='The Story Behind TranZact'/>
            </div>
            <div className='Story-text'>
                <p className='story-text1'>Our founders, Ritesh, Sharad, and Rohan, recognized the challenges 
                  that SMEs face in digitizing their sales and purchase operations. Drawing from their 
                  experiences as IIT graduates from the class of 2010, they set out to develop a solution that 
                  would bridge this gap.</p>
                <p className='story-text2'>With FiHD MRP, we focused on creating a business solution that 
                  integrates key functions across the sales and purchase cycle, ensuring it is easy to 
                  implement, simple to use, and scalable with our customers’ growth.</p>
                <p className='story-text3'>Before FiHD MRP, SMEs often had to rely on complex, expensive 
                  ERP systems meant for large enterprises to automate their processes. But not anymore. 
                  With our cloud-based, user-friendly, and cost-effective software, Indian businesses now 
                  have a powerful tool that simplifies and enhances their sales and purchase operations 
                  like never before.</p>
            </div>
        </div>
    </div>
  )
}

export default Story;

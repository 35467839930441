import React from 'react';
import "./Talent.css";

const Talent = () => {
  return (
    <div className='Talent'>
      <div className='Talent-text'>
        <h1 className='tal-text1'>We're<span className='tel-spl'> always</span> looking for <span className='tel-spl'>talent</span></h1>
        <button className='talent-button'>Apply Now</button>
      </div>
    </div>
  )
}

export default Talent;
